import React from 'react';
import { Row, Col, Form } from 'reactstrap';
import '../Configurator.css';
import './public.css';
import locale from '../../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import Helper from '../../Helper';
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table';
import Loading from 'react-fullscreen-loading';

interface IProps extends RouteComponentProps<any> {
	configurationId: any;
}

interface IState {
	documentation: any[];
	loading: boolean;
}

class ConfigurationDocuments extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			loading: false,
			documentation: [],		
		};
	}
	getDocumentation() {	
		this.setState({ loading: true });
		const state: IState = this.state;
		
		const url =
			'/configurator/' + this.props.match.params.configurationId+ '/documents';

		let documentation: any[] = [];
		Helper.getData<any>(url).then((data) => {
			if (data && data.length > 0) {			
				data.forEach((item: any) => {
					if (item.Url) {
						documentation.push({
							id: item.ConfigurationDocumentationId,
							url: item.Url,
							name: item.FileName,
							dateAdded: Helper.dateWithTimeConvert(item.DateModified),
							dateAddedDate: item.DateModified,
						});
					}
				});
			}
			state.documentation = documentation;
			this.setState(state);
			this.setState({ loading: false });
		});
	}
	componentDidMount() {
		this.getDocumentation();
	}
	render() {
		const options = {
			pageSize: 50,
			noDataText: locale.noResults,
		};

		const imgFormatter = (cell: any, row: any) => {
			if (row.url) {
				return (
					<a target='blank' className='btn cell-button' href={row.url + '?t=' + new Date().getTime()}>
						<i className='fa fa-file-image-o' title={locale.open}></i>
					</a>
				);
			} else {
				return '';
			}
		};

		const linkFormatter = (cell: any, row: any) => {
			if (row.url) {
				return (
					<a target='blank' className='btn cell-link' href={row.url + '?t=' + new Date().getTime()}>
						{row.name}
					</a>
				);
			} else {
				return '';
			}
		};

		const dateSort = (a: any, b: any, order: string) => {
			if (order === 'asc') {
				if (new Date(a.dateAddedDate) < new Date(b.dateAddedDate)) return 1;
				if (new Date(a.dateAddedDate) > new Date(b.dateAddedDate)) return -1;
			} else if (order === 'desc') {
				if (new Date(a.dateAddedDate) > new Date(b.dateAddedDate)) return 1;
				if (new Date(a.dateAddedDate) < new Date(b.dateAddedDate)) return -1;
			}
			return 0;
		};
		return (
			<div id='configurator' className='public'>
				{this.state.loading ? (
					<Loading loading loaderColor={Helper.themeButtonColor()} />
				) : (
					<Row>
						<Col xl='12' className='mt-3 configurator-background'>
							<Row>
								<Col xs='12' className='mt-3'>
									<Form>
										<Row>
											<Col className='customer-view pb-3'>
												<BootstrapTable
													data={this.state.documentation}
													options={options}
													striped
													keyField='id'
													tableStyle={{
														backgroundColor: 'white',
													}}
												>												
													<TableHeaderColumn dataField='image' dataFormat={imgFormatter} width='40px'>
														{' '}
													</TableHeaderColumn>

													<TableHeaderColumn
														columnTitle
														dataField='name'
														dataSort
														dataFormat={linkFormatter}
													>
														{locale.name}
													</TableHeaderColumn>

													<TableHeaderColumn
														dataField='dateAdded'
														dataSort
														sortFunc={dateSort}
														dataAlign='right'
														headerAlign='right'
													>
														{locale.date}
													</TableHeaderColumn>
												</BootstrapTable>
											</Col>
										</Row>
									</Form>
								</Col>
							</Row>
						</Col>
					</Row>
				)}
			</div>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(ConfigurationDocuments);
