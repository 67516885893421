import React from "react";
import Helper from "../Helper";
import { RouteComponentProps } from "react-router-dom";
import { connect } from "react-redux";
import { actionCreators } from "../store/Configurator";
import { bindActionCreators } from "redux";
import queryString from "query-string";

interface IProps extends RouteComponentProps<any> {
    footer?: any;
    brandPartnerId?: string;
}
interface IState {
    footer: { footer1text: any; footer1url: any; footer2text: any; footer2url: any; footer3text: any; footer3url: any; };
    brandPartnerId: any;
}
class AppFooter extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            footer: this.props.footer || "",
            brandPartnerId: this.props.brandPartnerId || ""
        };
    }

    getFooter(){
        const url = "/footer/" + this.state.brandPartnerId;
        Helper.getData<any>(url).then((data: any) => {
            if (data) {
                const footer1text = data.Footer1text || "";
                const footer1url = data.Footer1url || "";
                const footer2text = data.Footer2text || "";
                const footer2url = data.Footer2url || "";
                const footer3text = data.Footer3text || "";
                const footer3url = data.Footer3url || "";

                (this.props as any).setFooter(
                    footer1text,
                    footer1url,
                    footer2text,
                    footer2url,
                    footer3text,
                    footer3url
                );           
            }
        });
    }
    componentDidMount() {
        const state: IState = this.state;
        const queryValues = queryString.parse(this.props.location?.search);
        if (queryValues && queryValues.bpi) {
            state.brandPartnerId = queryValues.bpi.toString()
        }
        else {
            state.brandPartnerId = Helper.brandPartnerId()
        }
        this.setState(state);
        this.getFooter();
    }

    render() {
        const pathName = window.location.pathname.toLowerCase().replace(/\/$/, '');
		const isPublicDocuments = pathName.indexOf('documents') >= 0;

        const footer1text = (this.props.footer && Helper.getLocalizedName(this.props.footer.footer1text)) || "";
        const footer1url = (this.props.footer && this.props.footer.footer1url) || "";
        const footer2text = (this.props.footer && Helper.getLocalizedName(this.props.footer.footer2text)) || "";
        const footer2url = (this.props.footer && this.props.footer.footer2url) || "";
        const footer3text = (this.props.footer && Helper.getLocalizedName(this.props.footer.footer3text)) || "";
        const footer3url = (this.props.footer && this.props.footer.footer3url) || "";
        return !isPublicDocuments && (
            <div className="AppFooter">  
                <div className="AppFooterVersion">
                    v{process.env.REACT_APP_VERSION}
                </div>
                <div className="AppFooterVersion">
                    bpi:{this.state.brandPartnerId}
                </div>
                <div className="AppFooterCopyright">
                { (footer1text) ? (
                    <a href={footer1url} target="_blank" rel="noopener noreferrer" className="AppFooterLink">{footer1text}</a>
                    ): null
                }
                </div>
                <div className="AppFooterTerms">
                { (footer2text) ? (
                    <a href={footer2url} target="_blank" rel="noopener noreferrer" className="AppFooterLink">{footer2text}</a>
                    ): null
                }
                </div>
                <div className="AppFooterGdpr">
                { (footer3text) ? (
                    <a href={footer3url} target="_blank" rel="noopener noreferrer" className="AppFooterLink">{footer3text}</a>
                    ): null
                }
                </div>
            </div>
        )
    }
}
export default connect(
    (state: any) => state.configurator,
    dispatch => bindActionCreators(actionCreators, dispatch)
)(AppFooter) as any;
