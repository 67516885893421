const locale = {
	popular: 'Most popular',
	searchResults: 'Search results',
	price: 'Price',
	amount: 'Value',
	model: 'Model',
	name: 'Name',
	url: 'Url',
	company: 'Company',
	save: 'Save',
	filterTypeName: 'Type',
	filterGroupName: 'Style',
	filtersClear: 'Clear filters',
	filters: 'Filters',
	searchPlaceholder: 'Search...',
	configurationStep1: 'Model selection',
	configurationStep2: 'Model configuration',
	configurationStep3: 'Configuration overview',
	configurationStepCustomerView: 'Documents overview',
	select: 'Select',
	quantity: 'Quantity',
	overview: 'Overview',
	version: 'Version',
	modelAndConfig: 'Model / ID Config.',
	status: 'Status',
	noResults: 'No results',
	noData: 'No data',
	next: 'Next',
	prev: 'Back',
	nextPage: 'Next page',
	sendInquiry: 'Send inquiry',
	step: 'Step',
	of: 'of',
	comment: 'Comment',
	documentComment: 'Document comment',
	documentCommentRemove: 'Remove document comment',
	clearConfiguration: 'Reset configuration',
	cancel: 'Cancel',
	continue: 'Continue',
	close: 'Close',
	exception: 'Exception',
	generalException: 'Oops! Something went wrong.',
	apiException: 'Bad response from server',
	myProjects: 'My projects',
	allProjects: 'All projects',
	assignDocument: 'Assign (internally)',
	assignDocumentWithinCompany: 'Assign within the company',
	assignDocumentOutsideCompany: 'Assign outside the company',
	assignDocumentToContactTitle: 'Contact',
	assignDocumentToContactAddNew: 'Add new contact',
	assignDocumentToContactConfirmation: 'Are you sure you want to change the contact?',
	sendDocument: 'Send to partner',
	toggleProjectStatus: 'Project',
	unknownUser: 'Unknown',
	saveConfiguration: 'Add to cart',
	saveConfigurationToDocument: 'Save to document',
	addConfigurationToDocument: 'Add to document',
	customerNewOffer: 'Create document',
	customerProcess: 'Process',
	customerSendERP: 'Export to ERP',
	customerImportEdi: 'Import from ERP',
	customerNewVersion: 'Set default value',
	customerAssign: 'Assign to person',
	customerConfirmOrder: 'Release order confirmation',
	customerConfirmOrderMessage:
		'With this confirmation, the order (document no. {number}) is released for production. With confirmation I accept thereby associated financial obligations (payment obligation).',
	customerConfirmInqury: 'Confirm inquiry',
	customerConfirmInquryNewProject: 'Create in new project',
	customerSendToConfirm: 'Send to confirmation',
	customerSend: 'Send',
	customerForward: 'Forward',
	customerSendToSupplier: 'Send to supplier',
	customerSendOffer: 'Send document',
	customerSendDocument: 'Send document',
	customerDelete: 'Delete',
	customerDeleteConfirm: 'Are you sure you want to delete the selected record?',
	configurator: 'Configurator',
	open: 'Open',
	customerEditingTitleInquiry: 'Inquiry editing',
	customerEditingTitleOffer: 'Offer editing',
	documentEditing: 'Document editing',
	configurationEditing: 'Configuration editing',
	adminCalculationDiscounts: 'Discounts',
	adminCalculationAddDiscount: 'Add discount',
	adminCalculationAdditional: 'Accessories',
	adminCalculationCostsNet: 'Services',
	adminCalculationAdditionalCosts: "Accessories' cost",
	adminCalculationAddAdditionalCost: 'Add accessory',
	adminCalculationAddNetCost: 'Add service',
	adminCalculationConfigurationPrice: 'Configuration price',
	adminCalculationAdditionalSum: "Accessories' cost total",
	adminCalculationConfigurationWithAdditionalSum: 'Configuration price inc. accessories',
	adminCalculationDiscountPercentageSum: 'Discount percentage total',
	adminCalculationDiscountSum: 'Discount value total',
	adminCalculationConfigurationWithDiscountSum: 'Configuration price inc. accessories & discounts',
	adminCalculationServiceSum: 'Services cost total',
	adminCalculationSum: 'Value',
	adminRemark: 'Remark',
	adminRemarkAddImage: 'Upload image',
	adminDocumentationAdd: 'Upload attachment',
	adminDocumentationAddUrl: 'Add link',
	adminDocumentCustomer: 'Customer',
	adminDocumentContact: 'Contact',
	adminDocumentPartner: 'Responsible partner',
	adminDocumentPartnerRecipient: 'Partner',
	adminDocumentDateModified: 'Last modified date',
	adminDocumentStatus: 'Status',
	adminDocumentDocumentNumber: 'Document number',
	adminDocumentProject: 'Project',
	adminDocumentProjectStatus: 'Proj. status',
	adminDocumentDocumentOrder: 'Document order num.',
	adminDocumentBuyerOrderNumber: 'Buyer order num.',
	adminDocumentComission: 'Commission',
	adminDocumentCustomerNumber: 'Customer number',
	adminDocumentCustomerPosition: 'Customer position',
	adminDocumentCustomerSubPosition: 'Customer sub position',
	adminDocumentCommission: 'Commission',
	adminDocumentDeliveryTime: 'Delivery time',
	adminMenuDocument: 'Document data',
	adminMenuConfigurations: 'Configurations',
	adminMenuHistory: 'History',
	adminMenuConfiguration: 'Configuration',
	adminMenuCalculation: 'Configuration overview',
	adminMenuImages: 'Images',
	adminMenuSalesRemarks: 'Sales remarks',
	adminMenuInstallerRemarks: 'Installer remarks',
	adminMenuPosition: 'Position data',
	adminMenuDiscountsCosts: 'Discounts, Services, VAT',
	adminMenuTechnicalDocumentation: 'Technical documentation',
	adminMenuPrintDocument: 'Print and send',
	insideView: 'Inside view',
	facade: 'Facade',
	cartSuccess: 'By clicking the cart icon, you can edit its contents.',
	saving: 'Saving',
	contactTitle: 'Contact data and agreements',
	contactTitleSales: 'Contact data',
	inquirySalesDialog: 'Document',
	cart: 'Cart',
	cartSendInquiry: 'Send inquiry',
	cartRemove: 'Remove',
	cartInquirySentText: ', thank you for your inquiry. We will process it as soon as possible.',
	cartInquirySentText2: 'Thank you for your inquiry. We will process it as soon as possible.',
	cartInquirySentText3: 'Document created',
	cartInquirySent: 'Successfully sent request',
	cartOverride: 'Do you want to overwrite the existing configuration or create a new one?',
	cartSaving: 'Saving configuration',
	cartSavingSuccess: 'Your configuration was added to Cart',
	documentSaving: 'Saving document',
	cartButtonOverride: 'Overwrite existing configuration',
	cartButtonNew: 'Create new configuration',
	cartRemoveTitle: 'Configuration removal',
	cartRemovalText: 'Configuration succesfully removed',
	addDiscount: 'Discount',
	addCost: 'Cost',
	addNetCost: 'Service',
	discountText: 'Name',
	discountValue: 'Percent (%)',
	discountLevel: 'Level',
	discountType: 'Discount type',
	discount: 'Percent',
	sortOrder: 'Sort order',
	discountAmount: 'Discount amount',
	discountValueAfter: 'Value after discount',
	costText: 'Name',
	costValue: 'Amount',
	requestDocumentation: 'Request documentation',
	requestSpecialDocumentation: 'Request special documentation',
	loadSpecialDocumentation: 'Load special documentation',
	user: 'User',
	image: 'Image',
	date: 'Date',
	customer: 'Customer',
	partner: 'Partner',
	commission: 'Commission',
	responsiblePerson: 'Responsibne person',
	position: 'Position',
	value: 'Value',
	dataLastChange: 'Last change date',
	documentation: 'Documentation',
	inquiries: 'Inquiries',
	offers: 'Offers',
	printOffer: 'Print document',
	printDownload: 'Download',
	printChangeToSend: 'Change to Sent',
	printEditSent: 'Create email',
	printEmailSubject: 'Varialis - {number}',
	printEmailBody:
		'Dear Sir or Madam,\n\n\nBased on your inquiry please find the corresponding quotation nr. {number} in attachment. \n\nBest regards,\nVARIALIS portal\n\n\n\nDisclaimer: E-mails can contain confidential information that is only intended for the addressee. If the message was sent elsewhere due to an address or transmission error, please inform the author of the email. If the message was not intended for you, the information in the message may not be used, shared, disseminated, copied, printed or exploited in any way.',
	email: 'Email',
	password: 'Password',
	signIn: 'Sign In',
	signUp: 'Sign Up',
	profile: 'Profile',
	signingOut: 'Signing Out',
	salesman: 'Salesman',
	salesmanResponsible: 'Responsible salesman',
	signOut: 'Sign Out',
	submit: 'Submit',
	loginSuccess: '',
	loginError: 'There was a problem signin you in.',
	addressing: 'Title',
	firstName: 'First name',
	firstAndLastName: 'First and last name',
	lastName: 'Last name',
	loginFailed: 'Login failed',
	loginNoPass: 'Enter your password',
	loginNoUsername: 'Enter your email',
	forgotPassword: 'Forgot password?',
	resetPassword: 'Reset your password',
	resetPasswordMessage: 'Confirmation email has been sent, please check your inbox to proceed.',
	registrationFailed: 'Registration failed',
	missingFields: 'Please enter all required fields.',
	registrationSucceeded: 'Thank you for signing up. You received an email with link for finishing the registration process.',
	profileUpdateSucceeded: 'Your profile data has been updated.',
	profileDeliveryTitle: 'Delivery address',
	profileReceiptTitle: 'Receipt address',
	profileAddressAdd: 'Add address',
	profileAddressEdit: 'Edit address',
	differentThenContactAddress: 'Different than contact address',
	activationSucceeded: 'Activation succeeded',
	accountActivation: 'Account activation',
	confirmPassword: 'Confirm password',
	activate: 'Activate',
	missingToken: 'Invalid activation. Please sign up again.',
	telephone: 'Telephone',
	vat: 'VAT number',
	registrationNumber: 'Registration number',
	inquiryTitle:
		'Thank you for your inquiry. For the purpose of processing it, please fill in the contact information, check any (optional) consents and send the inquiry by pressing the Send button.<br/>You hereby consent that our company and our sales partners contact you by e-mail and/or telephone using the provided information in order to fulfill your inquiry request, make an offer or provide advice in relation to products. In case of an order, you allow us to pass on your data to verified professional staff for counseling and on-site survey so that the order can be fulfilled.',
	inquiryTitleCustomer: 'Fill customer contact data',
	inquiryTitleExistingCustomer: 'Select existing contact',
	inquiryDocumentType: 'Document type',
	address: 'Street and house number (Address)',
	city: 'City',
	zip: 'Zip',
	post: 'Postal Code',
	country: 'Country',
	selectCountry: 'Select country',
	selectDiscountType: 'Select discount type',
	companyName: 'Company name',
	companyRegistration: 'Company registration number',
	companyVAT: 'Company VAT ID',
	inquiryLegalAdd: 'Additionally for legal entities',
	inquiryConsents: 'Consents',
	inquiryConsent1Title: 'Offers and reminders',
	inquiryConsent1: 'e.g. informing you about relevant offers of products, spare parts, accessories and other products and services.',
	inquiryConsent2Title: 'Notifications',
	inquiryConsent2:
		'announcements and invitations to events at the launch of new models and catalogs, fairs, open days, other presentations and events.',
	inquiryConsent3Title: 'Surveys',
	inquiryConsent3: 'research to obtain your opinion to improve our products and services.',
	equalPasswords: 'Please enter equal passwords',
	type: 'Type',
	brands: 'Brands',
	partners: 'Partners',
	delete: 'Delete',
	languages: 'Languages',
	clearCache: 'Destroy cache',
	tags: 'Find tag',
	text: 'Text',
	desc: 'Description',
	configuration: 'Configuration',
	document: 'Document',
	edit: 'Edit',
	change: 'Change',
	view: 'View',
	clone: 'Item',
	copy: 'Copy',
	remove: 'Remove',
	default: 'Default',
	add: 'Add',
	deleting: 'Deleting...',
	adding: 'Adding...',
	cloning: 'Cloning...',
	print: 'Print',
	submitForProduction: 'Send to production',
	cartAddingDisabled:
		"You've reached saved projects limit. Please log in for unlimited number of projects.\nFor registration or login click the following icon in the upper right corner.",
	notSucceeded: 'failed',
	documentType: 'Document type',
	printPrice: 'Prices',
	printColorImage: 'Color image',
	printTechImage: 'Technical drawing',
	printConditions: 'Payment and delivery terms',
	selectPrintTemplate: 'Select template',
	selectPrintLanguage: 'Select language',
	sendToConfirmation: 'Send to confirmation',
	inConfirmation: 'In confirmation',
	isFinished: 'Finished',
	confirm: 'Confirm',
	reject: 'Reject',
	reset: 'Reset',
	downloadPriceFile: 'Download xlsx',
	termsPayment: 'Payment terms',
	termsSales: 'Sales terms',
	addTermPayment: 'Add payment term',
	addTermSales: 'Add delivery term',
	editTermPayment: 'Edit payment term',
	editTermSales: 'Edit delivery term',
	viewTermPayment: 'View sales term',
	viewTermSales: 'View delivery term',
	term: 'Term',
	termsPage: 'Payment and delivery Terms',
	send: 'Send',
	confirmDelete: 'Are you sure you want to delete the selected record?',
	confirmArchive: 'Are you sure you want to archive the selected record?',
	confirmReset: 'Are you sure you want to reset the selected configuration to default settings?',
	confirmClone: 'Are you sure you want to clone the selected record?',
	confirmCopy: 'Are you sure you want to copy the selected record?',
	confirmChangeStatusToSend: 'Are you sure you want to change the status of the document to Sent?',
	confirmChangeStatusSpecialTechnical:
		'Attention! By repeating request for tech. documentation the prepared special technical documentation will be lost.',
	confirmRequestDocumentation: 'Attention! By repeating request for tech. documentation the prepared special technical documentation will be lost.',
	confirmSend: 'Are you sure you want to send the document?',
	confirmSendDocument:
		'The recipient of the document is:<br/>- Partner: {Partner}<br/>- Salesman: {Salesman}<br/>- Contact: {Contact}<br/><br/> Are you sure you want to send the document?',
	changeRecipient: 'Change recipient',
	totalPriceWithoutTax: 'Total price without VAT',
	totalTax: 'Total VAT',
	finalPrice: 'Total price inc. VAT',
	selectTax: 'Select taxation',
	tax: 'VAT',
	printTemplateSelect: 'Document should include:',
	userUploaded: 'User uploaded',
	yes: 'Yes',
	no: 'No',
	contact: 'Contact',
	autoSaveConfiguration: 'Changes are saved automatically',
	loginToViewPrices: 'Please login to view prices',
	cookieLaw:
		'The website uses cookies to ensure its full functionality. Cookies store your use of the site. By using the Website, you authorize the use of these cookies. Visit for more information:',
	cookiesTitle: 'Information on the use of cookies',
	details: 'Details',
	sourceDocument: 'Originating doc.',
	download: 'Download',
	downloadPDF: 'Download PDF',
	clonePart: 'Item',
	imageUpload: 'Image upload',
	notImage: 'Uploaded picture must be of type jpg, png or bmp.',
	languageChange: 'Language change',
	languageChangeConfirm: 'By changing language you will be redirected to initial screen. Unsaved configuration changes will be disregarded.',
	brandChange: 'Brand change',
	brandChangeConfirm: 'By changing brand you will be redirected to initial screen. Unsaved configuration changes will be disregarded.',
	specialOffer: 'Special offer',
	archive: 'Archive',
	includeArchived: 'Show archived documents',
	showAll: 'Show all',
	cartEmpty: 'No items in cart',
	clipboard: 'Copied to clipboard',
	inputText: 'Please input text',
	inputNumber: 'Please input a number',
	twoDecimalPlaces: 'two decimal places',
	treeDetails: 'Parameter list',
	deliveryTime: 'Delivery',
};
export default locale;
