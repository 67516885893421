const locale = {
	popular: 'Le plus populaire',
	searchResults: 'Résultats de recherche',
	price: 'Prix',
	amount: 'Valeur',
	model: 'Modèle',
	name: 'Nom',
	url: 'Url',
	company: 'Société',
	save: 'sauvegarder',
	filterTypeName: 'Taper',
	filterGroupName: 'Style',
	filtersClear: 'Effacer les filtres',
	filters: 'Filtres',
	searchPlaceholder: 'Chercher...',
	configurationStep1: 'Sélection du modèle',
	configurationStep2: 'Configuration du modèle',
	configurationStep3: 'Présentation de la configuration',
	configurationStepCustomerView: 'Aperçu des documents',
	select: 'Sélectionner',
	quantity: 'Quantité',
	overview: 'Aperçu',
	version: 'Version',
	modelAndConfig: 'Modèle / ID Config.',
	status: 'Statut',
	noResults: 'Aucun résultat',
	noData: 'Pas de données',
	next: 'Prochain',
	prev: 'Arrière',
	nextPage: 'Page suivante',
	sendInquiry: 'Envoyer une demande',
	step: 'Étape',
	of: 'de',
	comment: 'Commenter',
	documentComment: 'Commentaire sur le document',
	documentCommentRemove: 'Supprimer le commentaire du document',
	clearConfiguration: 'Réinitialiser la configuration',
	cancel: 'Annuler',
	continue: 'Continuer',
	close: 'proche',
	exception: 'Exception',
	generalException: "Oups! Quelque chose s'est mal passé.",
	apiException: 'Mauvaise réponse du serveur',
	myProjects: 'Mes projets',
	allProjects: 'Tous les projets',
	assignDocument: 'Assigner (en interne)',
	assignDocumentWithinCompany: "Affecter au sein de l'entreprise",
	assignDocumentOutsideCompany: "Affecter à l'extérieur de l'entreprise",
	assignDocumentToContactTitle: 'Contacter',
	assignDocumentToContactAddNew: 'Ajouter un nouveau contact',
	assignDocumentToContactConfirmation: 'Êtes-vous sûr de vouloir changer le contact?',
	sendDocument: 'Envoyer au partenaire',
	toggleProjectStatus: 'Projet',
	unknownUser: 'Inconnu',
	saveConfiguration: 'Ajouter au panier',
	saveConfigurationToDocument: 'Enregistrer dans le document',
	addConfigurationToDocument: 'Ajouter au document',
	customerNewOffer: 'Créer un document',
	customerProcess: 'Traiter',
	customerSendERP: "Exporter vers l'ERP",
	customerImportEdi: "Importer depuis l'ERP",
	customerNewVersion: 'Définir la valeur par défaut',
	customerAssign: 'Attribuer à la personne',
	customerConfirmOrder: 'Valider conf. commande',
	customerConfirmOrderMessage:
		"Avec cette confirmation, la commande (document n° {numéro}) est validée pour la production. Avec confirmation, j'accepte les obligations financières associées (obligation de paiement).",
	customerConfirmInqury: 'Confirmer la demande',
	customerConfirmInquryNewProject: 'Créer dans un nouveau projet',
	customerSendToConfirm: 'Envoyer à la confirmation',
	customerSend: 'Envoyer',
	customerForward: 'Avant',
	customerSendToSupplier: 'Envoyer au fournisseur',
	customerSendOffer: 'Envoyer le document',
	customerSendDocument: 'Envoyer le document',
	customerDelete: 'Supprimer',
	customerDeleteConfirm: "Êtes-vous sûr de vouloir supprimer l'enregistrement sélectionné?",
	configurator: 'Configurateur',
	open: 'Ouvert',
	customerEditingTitleInquiry: "Édition d'enquête",
	customerEditingTitleOffer: "Modification de l'offre",
	documentEditing: 'Édition de documents',
	configurationEditing: 'Édition de configuration',
	adminCalculationDiscounts: 'Remises',
	adminCalculationAddDiscount: 'Ajouter une remise',
	adminCalculationAdditional: 'Accessoires',
	adminCalculationCostsNet: 'Prestations de service',
	adminCalculationAdditionalCosts: 'Coût des accessoires',
	adminCalculationAddAdditionalCost: 'Ajouter un accessoire',
	adminCalculationAddNetCost: 'Ajouter un service',
	adminCalculationConfigurationPrice: 'Prix de configuration',
	adminCalculationAdditionalSum: 'Coût total des accessoires',
	adminCalculationConfigurationWithAdditionalSum: 'Prix de configuration inc. accessoires',
	adminCalculationDiscountPercentageSum: 'Pourcentage de remise total',
	adminCalculationDiscountSum: 'Valeur totale de la remise',
	adminCalculationConfigurationWithDiscountSum: 'Prix de configuration inc. accessoires et remises',
	adminCalculationServiceSum: 'Coût total des services',
	adminCalculationSum: 'Valeur',
	adminRemark: 'Remarque',
	adminRemarkAddImage: 'Télécharger une image',
	adminDocumentationAdd: 'Télécharger la pièce jointe',
	adminDocumentationAddUrl: 'Ajouter un lien',
	adminDocumentCustomer: 'Client',
	adminDocumentContact: 'Contact',
	adminDocumentPartner: 'Partenaire responsable',
	adminDocumentPartnerRecipient: 'Partenaire',
	adminDocumentDateModified: 'Date de dernière modification',
	adminDocumentStatus: 'Statut',
	adminDocumentDocumentNumber: 'Numéro de document',
	adminDocumentProject: 'Projet',
	adminDocumentProjectStatus: "L'état du projet",
	adminDocumentDocumentOrder: 'Numéro de commande du document.',
	adminDocumentBuyerOrderNumber: "Numéro de commande de l'acheteur",
	adminDocumentComission: 'Commission',
	adminDocumentCustomerNumber: 'Numéro de client',
	adminDocumentCustomerPosition: 'Position client',
	adminDocumentCustomerSubPosition: 'Sous-position client',
	adminDocumentCommission: 'Commission',
	adminDocumentDeliveryTime: 'Date de livraison',
	adminMenuDocument: 'Données de document',
	adminMenuConfigurations: 'Configurations',
	adminMenuHistory: 'Histoire',
	adminMenuConfiguration: 'Configuration',
	adminMenuCalculation: 'Présentation de la configuration',
	adminMenuImages: 'Images',
	adminMenuSalesRemarks: 'Remarques de vente',
	adminMenuInstallerRemarks: "Remarques de l'installateur",
	adminMenuPosition: 'Données de position',
	adminMenuDiscountsCosts: 'Réductions, Services, TVA',
	adminMenuTechnicalDocumentation: 'Documentation technique',
	adminMenuPrintDocument: 'Imprimer et envoyer',
	insideView: "Vue de l'intérieur",
	facade: 'Façade',
	cartSuccess: "En cliquant sur l'icône du panier, vous pouvez modifier son contenu.",
	saving: 'Enregistrement',
	contactTitle: 'Coordonnées et accords',
	contactTitleSales: 'Données de contact',
	inquirySalesDialog: 'Document',
	cart: 'Panier',
	cartSendInquiry: 'Envoyer une demande',
	cartRemove: 'Supprimer',
	cartInquirySentText: ', merci pour votre demande. Nous le traiterons dès que possible.',
	cartInquirySentText2: 'Merci pour votre demande. Nous le traiterons dès que possible.',
	cartInquirySentText3: 'Document créé',
	cartInquirySent: 'Demande envoyée avec succès',
	cartOverride: 'Voulez-vous écraser la configuration existante ou en créer une nouvelle ?',
	cartSaving: 'Enregistrement de la configuration',
	cartSavingSuccess: 'Votre configuration a été ajoutée au panier',
	documentSaving: 'Enregistrement du document',
	cartButtonOverride: 'Écraser la configuration existante',
	cartButtonNew: 'Créer une nouvelle configuration',
	cartRemoveTitle: 'Suppression de la configuration',
	cartRemovalText: 'Configuration supprimée avec succès',
	addDiscount: 'Remise',
	addCost: 'Coût',
	addNetCost: 'Service',
	discountText: 'Nom',
	discountValue: 'Pourcentage (%)',
	discountLevel: 'Niveau',
	discountType: 'Type de remise',
	discount: 'Pour cent',
	sortOrder: 'Ordre de tri',
	discountAmount: 'Montant de la remise',
	discountValueAfter: 'Valeur après remise',
	costText: 'Nom',
	costValue: 'Montant',
	requestDocumentation: 'Demander des documents',
	requestSpecialDocumentation: 'Demander une documentation spéciale',
	loadSpecialDocumentation: 'Charger la documentation spéciale',
	user: 'Utilisateur',
	image: 'Image',
	date: 'Date',
	customer: 'Client',
	partner: 'Partenaire',
	commission: 'Commission',
	responsiblePerson: 'Personne responsable',
	position: 'Position',
	value: 'Valeur',
	dataLastChange: 'Date du dernier changement',
	documentation: 'Documentation',
	inquiries: 'Enquêtes',
	offers: 'Des offres',
	printOffer: 'Imprimer le document',
	printDownload: 'Télécharger',
	printChangeToSend: "Modifier l'e-mail et envoyer",
	printEditSent: 'Modifier et envoyer',
	printEmailSubject: 'Varialis - {number}',
	printEmailBody:
		"Cher Monsieur ou Madame,\n\n\nEn fonction de votre demande, veuillez trouver le devis correspondant nr. {number} en pièce jointe. \n\nCordialement,\nPortail VARIALIS\n\n\n\nAvertissement : Les e-mails peuvent contenir des informations confidentielles qui ne sont destinées qu'au destinataire. Si le message a été envoyé ailleurs en raison d'une adresse ou d'une erreur de transmission, veuillez en informer l'auteur de l'e-mail. Si le message ne vous était pas destiné, les informations contenues dans le message ne peuvent être utilisées, partagées, diffusées, copiées, imprimées ou exploitées de quelque manière que ce soit.",
	email: 'E-mail',
	password: 'Mot de passe',
	signIn: "S'identifier",
	signUp: "S'inscrire",
	profile: 'Profil',
	signingOut: 'Se déconnecter',
	salesman: 'Vendeur',
	salesmanResponsible: 'Vendeur responsable',
	signOut: 'Déconnecter',
	submit: 'Soumettre',
	loginSuccess: '',
	loginError: 'Un problème est survenu lors de votre connexion.',
	addressing: 'Titre',
	firstName: 'Prénom',
	firstAndLastName: 'Nom et prénom',
	lastName: 'Nom de famille',
	loginFailed: 'Échec de la connexion',
	loginNoPass: 'Tapez votre mot de passe',
	loginNoUsername: 'Entrer votre Email',
	forgotPassword: 'mot de passe oublié?',
	resetPassword: 'réinitialisez votre mot de passe',
	resetPasswordMessage: "L'e-mail de confirmation a été envoyé, veuillez vérifier votre boîte de réception pour continuer.",
	registrationFailed: "Échec de l'enregistrement",
	missingFields: 'Veuillez saisir tous les champs obligatoires.',
	registrationSucceeded: "Merci de vous être inscrit. Vous avez reçu un e-mail avec un lien pour terminer le processus d'inscription.",
	profileUpdateSucceeded: 'Vos données de profil ont été mises à jour.',
	profileDeliveryTitle: 'Adresse de livraison',
	profileReceiptTitle: 'Adresse de réception',
	profileAddressAdd: "Ajoutez l'adresse",
	profileAddressEdit: "Modifier l'adresse",
	differentThenContactAddress: "Différent de l'adresse de contact",
	activationSucceeded: 'Activation réussie',
	accountActivation: 'Activation de compte',
	confirmPassword: 'Confirmez le mot de passe',
	activate: 'Activer',
	missingToken: 'Activation non valide. Veuillez vous réinscrire.',
	telephone: 'Téléphone',
	vat: 'numéro de TVA',
	registrationNumber: "Numéro d'enregistrement",
	inquiryTitle:
		'Merci pour votre demande. Afin de les traiter, veuillez remplir les coordonnées, vérifier les éventuels consentements (facultatifs) et envoyer la demande en appuyant sur le bouton Envoyer.<br/>Vous consentez par la présente à ce que notre société et nos partenaires commerciaux vous contactent par e-mail et/ou téléphoner en utilisant les informations fournies afin de répondre à votre demande de renseignements, de faire une offre ou de fournir des conseils concernant les produits. En cas de commande, vous nous autorisez à transmettre vos données à un personnel professionnel vérifié pour des conseils et une enquête sur place afin que la commande puisse être exécutée.',
	inquiryTitleCustomer: 'Remplir les coordonnées du client',
	inquiryTitleExistingCustomer: 'Sélectionnez un contact existant',
	inquiryDocumentType: 'Type de document',
	address: 'Adresse',
	city: 'Ville',
	zip: 'Zipper',
	post: 'Code postal',
	country: 'Pays',
	selectCountry: 'Choisissez le pays',
	selectDiscountType: 'Sélectionnez le type de remise',
	companyName: 'Nom de la compagnie',
	companyRegistration: "Numéro d'entreprise",
	companyVAT: "Numéro de TVA de l'entreprise",
	inquiryLegalAdd: 'En plus pour les personnes morales',
	inquiryConsents: 'Consentements',
	inquiryConsent1Title: 'Offres et rappels',
	inquiryConsent1: 'par exemple. vous informer sur les offres pertinentes de produits, pièces détachées, accessoires et autres produits et services.',
	inquiryConsent2Title: 'Avis',
	inquiryConsent2:
		'annonces et invitations à des événements lors du lancement de nouveaux modèles et catalogues, salons, journées portes ouvertes, autres présentations et événements.',
	inquiryConsent3Title: 'Enquêtes',
	inquiryConsent3: "recherche pour obtenir votre avis afin d'améliorer nos produits et services.",
	equalPasswords: 'Veuillez saisir des mots de passe identiques',
	type: 'Taper',
	brands: 'Marques',
	partners: 'Les partenaires',
	delete: 'Effacer',
	languages: 'Langues',
	clearCache: 'Détruire le cache',
	tags: 'Rechercher une balise',
	text: 'Texte',
	desc: 'La description',
	configuration: 'Configuration',
	document: 'Document',
	edit: 'Éditer',
	change: 'Changer',
	view: 'Vue',
	clone: 'Cloner',
	copy: 'Copie',
	remove: 'Supprimer',
	default: 'Défaut',
	add: 'Ajouter',
	deleting: 'Suppression...',
	adding: 'Ajouter...',
	cloning: 'Clonage…',
	print: 'Imprimer',
	submitForProduction: 'Envoyer à la production',
	cartAddingDisabled:
		"Vous avez atteint la limite de projets enregistrés. Veuillez vous connecter pour un nombre illimité de projets.\nPour vous inscrire ou vous connecter, cliquez sur l'icône suivante dans le coin supérieur droit.",
	notSucceeded: 'échoué',
	documentType: 'Type de document',
	printPrice: 'Des prix',
	printColorImage: 'Image en couleur',
	printTechImage: 'Dessin technique',
	printConditions: 'Conditions de paiement et de livraison',
	selectPrintTemplate: 'Sélectionnez le modèle',
	selectPrintLanguage: 'Choisir la langue',
	sendToConfirmation: 'Envoyer à la confirmation',
	inConfirmation: 'En confirmation',
	isFinished: 'Fini',
	confirm: 'Confirmer',
	reject: 'Rejeter',
	reset: 'Réinitialiser',
	downloadPriceFile: 'Télécharger xlsx',
	termsPayment: 'Modalités de paiement',
	termsSales: 'Conditions de vente',
	addTermPayment: 'Ajouter un délai de paiement',
	addTermSales: 'Ajouter un délai de livraison',
	editTermPayment: 'Modifier les conditions de paiement',
	editTermSales: 'Modifier le délai de livraison',
	viewTermPayment: 'Voir les conditions de vente',
	viewTermSales: 'Voir le délai de livraison',
	term: 'Nom',
	termsPage: 'Conditions de paiement et de livraison',
	send: 'Envoyer',
	confirmDelete: "Êtes-vous sûr de vouloir supprimer l'enregistrement sélectionné ?",
	confirmArchive: "Êtes-vous sûr de vouloir archiver l'enregistrement sélectionné ?",
	confirmReset: 'Voulez-vous vraiment réinitialiser la configuration sélectionnée aux paramètres par défaut ?',
	confirmClone: "Êtes-vous sûr de vouloir cloner l'enregistrement sélectionné ?",
	confirmCopy: "Êtes-vous sûr de vouloir copier l'enregistrement sélectionné ?",
	confirmChangeStatusToSend: 'Êtes-vous sûr de vouloir changer le statut du document en Envoyé?',
	confirmChangeStatusSpecialTechnical:
		'Attention! En répétant la demande de technologie. documentation la documentation technique spéciale préparée sera perdue.',
	confirmRequestDocumentation:
		'Attention! En répétant la demande de technologie. documentation la documentation technique spéciale préparée sera perdue.',
	confirmSend: 'Êtes-vous sûr de vouloir envoyer le document?',
	confirmSendDocument:
		'Le destinataire du document est :<br/>- Partenaire: {Partner}<br/>- Vendeur: {Salesman}<br/>- Contact: {Contact}<br/><br/> Êtes-vous sûr de vouloir envoyer le document?',
	changeRecipient: 'Changer de destinataire',
	totalPriceWithoutTax: 'Prix total sans TVA',
	totalTax: 'TVA totale',
	finalPrice: 'Prix total TTC T.V.A.',
	selectTax: 'Sélectionnez la fiscalité',
	tax: 'T.V.A.',
	printTemplateSelect: 'Le document doit inclure :',
	userUploaded: 'Utilisateur téléchargé',
	yes: 'Oui',
	no: 'Non',
	contact: 'Contact',
	autoSaveConfiguration: 'Les modifications sont enregistrées automatiquement',
	loginToViewPrices: 'Veuillez vous connecter pour voir les prix',
	cookieLaw:
		"Le site Web utilise des cookies pour assurer sa pleine fonctionnalité. Les cookies stockent votre utilisation du site. En utilisant le Site, vous autorisez l'utilisation de ces cookies. Visitez pour plus d'informations :",
	cookiesTitle: "Informations sur l'utilisation des cookies",
	details: 'Des détails',
	sourceDocument: "Doc. d'origine",
	download: 'Télécharger',
	downloadPDF: 'Télécharger le PDF',
	clonePart: 'Article',
	imageUpload: "Téléchargement d'images",
	notImage: "L'image téléchargée doit être de type jpg, png ou bmp.",
	languageChange: 'Changement de langue',
	languageChangeConfirm:
		"En changeant de langue, vous serez redirigé vers l'écran initial. Les modifications de configuration non enregistrées seront ignorées.",
	brandChange: 'Changement de marque',
	brandChangeConfirm:
		"En changeant de marque, vous serez redirigé vers l'écran initial. Les modifications de configuration non enregistrées seront ignorées.",
	specialOffer: 'Offre spéciale',
	archive: 'Archiver',
	includeArchived: 'Afficher les documents archivés',
	showAll: 'Afficher tout',
	cartEmpty: 'Aucun article dans le panier',
	clipboard: 'Copié dans le presse-papier',
	inputText: 'Veuillez saisir du texte',
	inputNumber: 'Veuillez saisir un numéro',
	twoDecimalPlaces: 'Deux places de décimales',
	treeDetails: 'Liste de paramètres',
	deliveryTime: 'Livraison',
};
export default locale;
