import React, { Fragment } from 'react';
import { NavLink as RRNavLink } from 'react-router-dom';
import { Collapse, Container, Navbar, NavItem, NavLink, NavbarToggler } from 'reactstrap';
import './SubNavMenu.css';
import locale from '../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import { RouteComponentProps } from 'react-router-dom';
import ErrorModal from './ErrorModal';
import Helper from '../Helper';
import { history } from '../index';

interface IProps extends RouteComponentProps<any> {
	part: any;
	configuration: any;
	document?: any;
	user?: any;
	navCartOpen?: boolean;
	inquiryDialogOpen?: boolean;
}

interface IState {
	isOpen: boolean;
}

class SubNavMenu extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);

		this.state = {
			isOpen: false,
		};
	}
	componentDidUpdate(nextProps: any) {
		if (nextProps.navCartOpen === true) {
			(this.props as any).setCartOpen(false);
		}
		if (nextProps.inquiryDialogOpen === true) {
			(this.props as any).setInquiryDialogOpen(false);
		}
	}
	toggle() {
		const state: IState = this.state;
		state.isOpen = !state.isOpen;
		this.setState(state);
	}
	goToDocument() {
		history.push(process.env.REACT_APP_ROOT_PATH + '/admin/document/configurations');
	}
	goToProjects(filter: string) {
		history.push(process.env.REACT_APP_ROOT_PATH + '/admin/myProjects?project=' + filter);
	}
	goToConfiguration() {
		history.push(process.env.REACT_APP_ROOT_PATH + '/admin/document/configuration');
	}
	render() {
		const baseName = (process.env.REACT_APP_ROOT_PATH || '').toLowerCase();
		const pathName = window.location.pathname.toLowerCase().replace(/\/$/, '');

		const checkActiveConfiguration = () => pathName.indexOf('admin/configuration') >= 0;
		const checkActiveDocument = () => pathName.indexOf('admin/document') >= 0;
		const checkActiveMyProjects = () => pathName.indexOf('admin/myprojects') >= 0;
		const checkActiveAllProjects = () => pathName.indexOf('admin/allprojects') >= 0;

		const checkActiveStep1 = () =>
			pathName === baseName + '/' ||
			pathName === baseName + '' ||
			pathName === baseName + '/configurator' ||
			pathName === baseName + '/configurator/selection';

		const isStep1 = () => pathName.indexOf('configurator/selection') >= 0;

		const isStep2 = () => pathName.indexOf('configurator/configuration') >= 0;

		const isStep3 = () => pathName.indexOf('configurator/confirmation') >= 0;

		const isPublicDocuments = pathName.indexOf('documents') >= 0;

		const navTitle = checkActiveConfiguration()
			? locale.configurationEditing
			: checkActiveDocument()
			? locale.documentEditing
			: checkActiveMyProjects()
			? locale.myProjects
			: checkActiveAllProjects()
			? locale.allProjects
			: checkActiveStep1()
			? locale.configurationStep1
			: isStep2()
			? locale.configurationStep2
			: isStep3()
			? locale.configurationStep3
			: '';

		const adminVisible = Helper.userEditor();
		const headOfSalesVisible = Helper.userHeadOfSales();
		const installerVisible = Helper.userInstaller();

		const documentDisabled = !this.props.document || !this.props.document.documentId;
		const documentConfigurationDisabled =
			!this.props.configuration || !this.props.configuration.configurationId || !this.props.configuration.documentConfigurationId;
		const configurationDisabled = !this.props.configuration || !this.props.configuration.configurationId;

		const configurationVersion =
			this.props.configuration && this.props.configuration.documentPosition ? ' (' + this.props.configuration.documentPosition + ')' : '';

		const modelAndPriceDiv = (
			<Fragment>
				{this.props.document && this.props.document.documentId && this.props.document.project ? (
					<div className='model' onClick={() => this.goToProjects(this.props.document.project)} style={{ cursor: 'pointer' }}>
						<label className='smaller'>{locale.adminDocumentProject}</label>
						<div>
							<b>{this.props.document.project}</b>
						</div>
					</div>
				) : null}

				{this.props.document && this.props.document.documentId && this.props.document.name ? (
					<div className='model' onClick={() => this.goToDocument()} style={{ cursor: 'pointer' }}>
						<label className='smaller'>{locale.document}</label>
						<div>
							<b>
								{this.props.document.name}
								{configurationVersion}
							</b>
						</div>
					</div>
				) : null}

				{this.props.part && this.props.part.name && (isStep1() || isStep2() || isStep3()) ? (
					<div className='model'>
						<label className='smaller'>{locale.model}</label>
						<div>
							<b>
								{Helper.getLocalizedValueOrNull(this.props.part.brandName) ??
									Helper.getLocalizedValueOrNull(this.props.part.title) ??
									this.props.part.name}
							</b>
						</div>
					</div>
				) : null}

				{this.props.part && this.props.part.price && Helper.userLoggedIn() && (isStep1() || isStep2() || isStep3()) ? (
					<div className='model'>
						<label className='smaller'>{locale.price}</label>
						<div>
							<b>
								{this.props.part.price.toLocaleString('de-DE', {
									style: 'currency',
									currency: this.props.document.currency,
								})}
							</b>
						</div>
					</div>
				) : null}
			</Fragment>
		);

		return !isPublicDocuments && (
			<Navbar className='navbar-expand-lg navbar-toggleable-lg subnavbar navbar-light'>
				<Container fluid>
					<Collapse className='inline-flex' isOpen={this.state.isOpen} navbar>
						<ul className='navbar-nav subnavbar-nav flex-grow social d-flex'>
							{pathName.indexOf('/admin') === -1 || !adminVisible ? (
								<Fragment>
									{pathName.indexOf('/profile') === -1 || !(adminVisible || headOfSalesVisible || installerVisible) ? (
										<NavItem className='subnav-item'>
											<NavLink
												tag={RRNavLink}
												className='p-sm text-dark subnav-link'
												isActive={checkActiveStep1}
												activeClassName='active'
												to={baseName + '/configurator/selection'}
											>
												{locale.configurationStep1}
											</NavLink>
										</NavItem>
									) : null}
									<NavItem className={'subnav-item ' + (configurationDisabled ? 'd-none' : '')}>
										<NavLink
											disabled={!this.props.part || !this.props.part.id}
											tag={RRNavLink}
											className='text-dark subnav-link'
											activeClassName='active'
											to={baseName + '/configurator/configuration'}
										>
											{locale.configurationStep2}
										</NavLink>
									</NavItem>
									<NavItem className={'subnav-item ' + (configurationDisabled ? 'd-none' : '')}>
										<NavLink
											disabled={!this.props.part || !this.props.part.id}
											tag={RRNavLink}
											className='text-dark subnav-link'
											activeClassName='active'
											to={baseName + '/configurator/confirmation'}
										>
											{locale.configurationStep3}
										</NavLink>
									</NavItem>
									{adminVisible ? (
										<NavItem className='subnav-item'>
											<NavLink tag={RRNavLink} className='text-dark subnav-link' activeClassName='active' to={baseName + '/admin/myProjects'}>
												{locale.myProjects}
											</NavLink>
										</NavItem>
									) : null}
								</Fragment>
							) : ( 						
								<Fragment>
									{!installerVisible ? (
										<NavItem className='subnav-item'>
											<NavLink
												tag={RRNavLink}
												isActive={checkActiveMyProjects}
												className='text-dark subnav-link'
												activeClassName='active'
												to={baseName + '/admin/myProjects'}
											>
												{locale.myProjects}
											</NavLink>
										</NavItem>
									) : null}
									{headOfSalesVisible || installerVisible ? (
										<NavItem className='subnav-item'>
											<NavLink
												tag={RRNavLink}
												isActive={checkActiveAllProjects}
												className='text-dark subnav-link'
												activeClassName='active'
												to={baseName + '/admin/allProjects'}
											>
												{locale.allProjects}
											</NavLink>
										</NavItem>
									) : null}
									<NavItem className={'subnav-item ' + (documentDisabled ? 'd-none' : '')}>
										<NavLink
											tag={RRNavLink}
											id='customerViewEditingTitle'
											disabled={documentDisabled}
											isActive={checkActiveDocument}
											className='text-dark subnav-link'
											activeClassName='active'
											to={baseName + '/admin/document/configurations'}
										>
											{locale.documentEditing}
										</NavLink>
									</NavItem>
									<NavItem className={'subnav-item ' + (documentConfigurationDisabled ? 'd-none' : '')}>
										<NavLink
											tag={RRNavLink}
											id='customerViewEditingTitle'
											disabled={documentConfigurationDisabled}
											isActive={checkActiveConfiguration}
											className='text-dark subnav-link'
											activeClassName='active'
											to={baseName + '/admin/configuration/calculation'}
										>
											{locale.configurationEditing + configurationVersion}{' '}
										</NavLink>
									</NavItem>
								</Fragment>
							)}
						</ul>
						<div className='model-price-wrapper text-dark d-none d-lg-block'>{modelAndPriceDiv}</div>
					</Collapse>
					<NavbarToggler onClick={() => this.toggle()} className='mr-2 d-block d-lg-none' />
					<div className='navbar-title'>{navTitle}</div>
					<div className='model-price-wrapper text-dark d-block d-lg-none'>{modelAndPriceDiv}</div>

					<ErrorModal />
				</Container>
			</Navbar>
		);
	}
}
export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch),
	undefined,
	{ pure: false }
)(SubNavMenu) as any;
