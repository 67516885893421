import React, { Fragment } from 'react';
import { Row, Col, Label, Modal, ModalHeader, ModalBody } from 'reactstrap';
import './Cart.css';
import NumericInput from 'react-numeric-input';
import locale from '../Locale';
import Helper from '../Helper';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import Inquiry from './Inquiry';
import Notify from './Notify';
import Button from './Button';
import InquiryUser from './InquiryUser';
import { history } from '../index';
import Loading from 'react-fullscreen-loading';

interface IProps {
	anonymousToken?: string;
	configuration?: any;
	contact?: string;
	cartOpen: boolean;
	inquiryDialogOpen?: boolean;
	closeCart(): void;
	theme?: any;
	user?: any;
	showMessage?: any;
}
interface IState {
	configurations: any;
	notify: any;
	inquiryModal: boolean;
	inquiryCustomerModal: boolean;
	inquiryUserModal: boolean;

	loading: boolean;
}
class Cart extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			notify: { open: false, title: '', message: '', isError: false },
			configurations: [],
			inquiryModal: false,
			inquiryCustomerModal: false,
			inquiryUserModal: false,
			loading: false,
		};
	}
	componentDidMount() {
		if (this.props.cartOpen) {
			this.cart();
		}
		if (this.props.inquiryDialogOpen) {
			this.toggleInquiryModal();
		}
	}
	componentDidUpdate(nextProps: any) {
		if ((nextProps.user !== this.props.user || nextProps.configuration !== this.props.configuration) && this.props.cartOpen) {
			this.cart();
		}
	}
	removeConfiguration(configurationId: string) {
		const state: IState = this.state;

		let url = '/cart/' + configurationId;
		if (this.props.anonymousToken && !localStorage.getItem('userToken')) {
			url += '/' + this.props.anonymousToken;
		}

		Helper.deleteData<any>(url).then((data) => {
			if (data.Message && data.Message.length > 0) {
				(this.props as any).setError(locale.exception, data.Message);
				this.setState(state);
			}
			this.setState(state);
			this.cart();
		});
		(this.props as any).setConfigurationPublished(false);
	}
	configurationData(configData: any): any {
		let configurations: any = [];
		if (configData && configData.length > 0 && Array.isArray(configData)) {
			configData.forEach((item: any) => {
				configurations.push({
					id: item.ConfigurationId,
					modelName: Helper.getLocalizedName(item.ConfigurationName),
					image: item.Image,
					quantity: item.Quantity === 0 ? 1 : item.Quantity,
					price: item.ConfigurationPrice
						? item.ConfigurationPrice.toLocaleString('de-DE', {
								style: 'currency',
								currency: 'EUR',
						  })
						: '',
				});
			});
		}
		return configurations;
	}
	cart() {
		const state: IState = this.state;
		if (!this.props.anonymousToken && !localStorage.getItem('userToken')) {
			state.configurations = [];
			state.loading = false;
			this.setState(state);
			return;
		}
		this.setState({ loading: true });
		let url = '/cart/user';
		if (this.props.anonymousToken && !localStorage.getItem('userToken')) {
			url = '/cart/' + this.props.anonymousToken;
		}
		Helper.getData<any>(url).then((data) => {
			let items = this.configurationData(data);
			state.configurations = items;
			state.loading = false;
			this.setState(state);
		});
	}
	setConfiguration(configurationId: string) {
		this.props.closeCart();
		(this.props as any).buildCategories(configurationId);
		if (window.location.pathname.indexOf('/configurator/configuration') === -1) {
			history.push(process.env.REACT_APP_ROOT_PATH + '/configurator/configuration');
		}
	}
	createSalesDocument(contactData: any) {
		const state: IState = this.state;
		const urlConfiguration = '/cart/inquiry';
		let configurationsBody: any[] = [];
		for (let i = 0; i < state.configurations.length; i++) {
			configurationsBody.push(state.configurations[i].id);
		}
		const body = {
			UserTokenId: this.props.anonymousToken,
			BrandPartnerId: Helper.brandPartnerId(),
			Configurations: configurationsBody,
			ConfigurationContact: contactData,
			WebUrl: window.location.protocol + '//' + window.location.host + process.env.REACT_APP_ROOT_PATH + '/configurator/confirmation',
			Lang: Helper.userLang(),
		};
		Helper.postData<any>(urlConfiguration, JSON.stringify(body))
			.then((data) => {
				if (data) {
					if (data && data.Message) {
						state.notify.message = data.Message;
						state.notify.title = locale.exception;
						state.notify.open = true;
						this.setState(state);
					} else {
						state.configurations = [];
						(this.props as any).setConfigurationPublished(false);
						(this.props as any).clearConfiguration();

						let contactName = '';
						state.notify.title = locale.cartInquirySent;
						if (Helper.userAdmin()) {
							state.notify.message = locale.cartInquirySentText3;
							state.notify.title = locale.cartInquirySentText3;
						} else if (contactData && contactData.Name && contactData.Name.indexOf(' ') > 0) {
							contactName = contactData.Name.split(' ')[0];
							state.notify.message = contactName + locale.cartInquirySentText;
						} else if (this.props.user && this.props.user.name) {
							state.notify.message = this.props.user.name + locale.cartInquirySentText;
						} else {
							state.notify.message = locale.cartInquirySentText2;
						}

						(this.props as any).setShowMessage(true, state.notify.title, state.notify.message);

						if (Helper.userInstaller()) {
							window.location.href = process.env.REACT_APP_ROOT_PATH + '/admin/allProjects';
						} else if (Helper.userEditor()) {
							window.location.href = process.env.REACT_APP_ROOT_PATH + '/admin/myProjects';
						} else {
							window.location.href = process.env.REACT_APP_ROOT_PATH + '/configurator/selection';
						}
					}
				}
			})
			.catch((error) => {
				state.notify.message = error.message;
				state.notify.isError = true;
				state.notify.open = true;
				console.log(error);
				state.inquiryModal = false;
				state.inquiryCustomerModal = false;
				state.inquiryUserModal = false;

				this.setState(state);
			});
	}

	openConfiguration(configurationId: string) {
		this.setConfiguration(configurationId);
	}
	toggleInquiryModal() {
		if (localStorage.getItem('userToken')) {
			this.toggleInquiryUserModal();
		} else {
			const state: IState = this.state;
			state.inquiryModal = !state.inquiryModal;
			this.setState(state);
		}
	}
	toggleInquiryUserModal() {
		const state: IState = this.state;
		state.inquiryUserModal = !state.inquiryUserModal;
		this.setState(state);
	}
	setQuantity(id: string, value: number) {
		this.setState({ loading: true });

		let url = '/cart/' + id;
		if (this.props.anonymousToken && !localStorage.getItem('userToken')) {
			url += '/' + this.props.anonymousToken;
		}

		const body = {
			Quantity: value,
		};
		Helper.putData<any>(url, JSON.stringify(body)).then((data) => {
			if (data) {
				this.cart();
			}
			this.setState({ loading: false });
		});
	}
	setUserContactData(contactData: any) {
		if (
			contactData &&
			contactData.ContactData.Email &&
			contactData.ContactData.FirstName &&
			contactData.ContactData.LastName &&
			contactData.ContactData.Email !== '' &&
			contactData.ContactData.FirstName !== '' &&
			contactData.ContactData.LastName !== ''
		) {
			(this.props as any).setContact(contactData);
			this.toggleInquiryModal();
			this.createSalesDocument(contactData);
		}
	}
	setUserAddressData(contactData: any) {
		(this.props as any).setContact(contactData);
		this.toggleInquiryModal();
		this.createSalesDocument(contactData);
	}
	render() {
		let inputColor = this.props.theme && this.props.theme.color ? this.props.theme.color : '#86A33C';
		let r = parseInt(inputColor.slice(1, 3), 16),
			g = parseInt(inputColor.slice(3, 5), 16),
			b = parseInt(inputColor.slice(5, 7), 16);
		inputColor = 'rgba(' + r + ', ' + g + ', ' + b + ', ' + 0.2 + ')';

		const { configurations } = this.state;
		const configurationList = configurations.map((item: any, i: number) => {
			let selectedConfigurationClassName = '';

			if (item.id === this.props.configuration.configurationId) {
				selectedConfigurationClassName = ' cart-selected';
			}
			return (
				<Fragment key={i}>
					<Row
						className={'d-none d-md-flex pt-3 pb-3 horizontal-line' + selectedConfigurationClassName}
						style={{
							backgroundColor: selectedConfigurationClassName !== '' ? inputColor : '',
						}}
					>
						<Col md='2'>
							<img
								src={item.image + '?t=' + new Date().getTime()}
								className='cart-image'
								alt={' '}
								style={{ display: item.image ? 'inline' : 'none' }}
							/>
						</Col>
						<Col md='5'>{item.modelName}</Col>
						{Helper.userLoggedIn() ? (
							<>
								<Col md='2'>{item.price}</Col>
								<Col md='2'>
									<NumericInput
										autoComplete='off'
										value={item.quantity}
										min={1}
										max={100}
										onChange={(value: any) => this.setQuantity(item.id, value)}
									/>
								</Col>
							</>
						) : (
							<Col md='4'>
								<NumericInput
									autoComplete='off'
									value={item.quantity}
									min={1}
									max={100}
									onChange={(value: any) => this.setQuantity(item.id, value)}
								/>
							</Col>
						)}
						<Col md='1'>
							<Button className='cart-submit-button small-button' isNarrow={true} onClick={() => this.openConfiguration(item.id)} color='secondary'>
								{locale.select}
							</Button>
							<Button className='cart-remove-button small-button' isNarrow={true} onClick={() => this.removeConfiguration(item.id)} color='secondary'>
								{locale.cartRemove}
							</Button>
						</Col>
					</Row>
					<Row
						className={'d-flex d-md-none pt-3 pb-3 horizontal-line' + selectedConfigurationClassName}
						style={{
							backgroundColor: selectedConfigurationClassName !== '' ? inputColor : '',
						}}
					>
						<Col xs='2'>
							<img
								src={item.image + '?t=' + new Date().getTime()}
								className='cart-image'
								alt={' '}
								style={{ display: item.image ? 'inline' : 'none' }}
							/>
						</Col>
						<Col xs='5'>
							<Row>
								<Col style={{ fontSize: '12px' }}>
									<b>{item.modelName}</b>
								</Col>
							</Row>
							<Row>
								<Col style={{ fontSize: '12px' }}>{item.price}</Col>
							</Row>
						</Col>
						<Col xs='3'>
							<NumericInput autoComplete='off' value={item.quantity} min={1} max={100} onChange={(value: any) => this.setQuantity(item.id, value)} />
						</Col>
						<Col xs='2'>
							<Button
								className='cart-submit-button-mobile float-right small-button'
								onClick={() => this.openConfiguration(item.id)}
								color='secondary'
							>
								{locale.select}
							</Button>
							<Button
								className='cart-remove-button-mobile float-right small-button'
								onClick={() => this.removeConfiguration(item.id)}
								color='secondary'
							>
								{locale.cartRemove}
							</Button>
						</Col>
					</Row>
				</Fragment>
			);
		});
		const hasResults: boolean = configurationList && configurationList.length;
		return (
			<Fragment>
				{this.state.notify.open ? (
					<Notify message={this.state.notify.message} isError={this.state.notify.isError} title={this.state.notify.title} />
				) : null}

				<Modal backdrop='static' isOpen={this.state.inquiryModal} toggle={() => this.toggleInquiryModal()} className='inquiry-popup'>
					<ModalHeader toggle={() => this.toggleInquiryModal()}>{locale.contactTitle}</ModalHeader>
					<ModalBody>
						<Inquiry contact={this.props.contact} setUserContactData={(contactData: any) => this.setUserContactData(contactData)} />
					</ModalBody>
				</Modal>
				<Modal backdrop='static' isOpen={this.state.inquiryUserModal} toggle={() => this.toggleInquiryUserModal()} className='inquiry-popup'>
					<ModalHeader toggle={() => this.toggleInquiryUserModal()}>{locale.contactTitleSales}</ModalHeader>
					<ModalBody>
						<InquiryUser contact={this.props.contact} setUserAddressData={(contactData: any) => this.setUserAddressData(contactData)} />
					</ModalBody>
				</Modal>
				<div id='cart'>
					{this.state.loading ? (
						<Loading loading loaderColor={Helper.themeButtonColor()} />
					) : (
						<div className='cart-container'>
							<Row className='cart-header'>
								<Col className='d-none d-md-block' md='2'>
									<Label>{locale.overview}</Label>
								</Col>
								<Col className='d-none d-md-block' md='5'>
									<Label>{locale.name}</Label>
								</Col>
								{Helper.userLoggedIn() ? (
									<Col className='d-none d-md-block' md='2'>
										<Label>{locale.price}</Label>
									</Col>
								) : null}
								<Col className='d-none d-md-block' md='2'>
									<Label>{locale.quantity}</Label>
								</Col>
								<Col className='d-none d-md-block' md='1'></Col>
							</Row>
							<div className='cart-content'>{hasResults ? configurationList : <div className='text-center my-2'>{locale.cartEmpty}</div>}</div>
							<Row>
								<Col xs='12 mt-3'>
									{hasResults ? (
										<Fragment>
											<Button
												className='float-sm-left d-block d-sm-inline-block small-button'
												color='secondary'
												onClick={() => this.toggleInquiryModal()}
											>
												{locale.cartSendInquiry}
											</Button>
										</Fragment>
									) : null}
									<Button
										className='float-sm-right d-block d-sm-inline-block mt-1 mt-sm-0 small-button'
										onClick={() => this.props.closeCart()}
										color='secondary'
									>
										{locale.close}
									</Button>
								</Col>
							</Row>
						</div>
					)}
				</div>
			</Fragment>
		);
	}
}

export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(Cart);
