import React from 'react';
import {
	Container,
	Navbar,
	NavbarToggler,
	NavItem,
	NavLink,
	Dropdown,
	DropdownToggle,
	DropdownMenu,
	DropdownItem,
	Button,
	UncontrolledDropdown,
	Modal,
	ModalHeader,
	ModalBody,
} from 'reactstrap';
import './NavMenu.css';
import { NavLink as RRNavLink } from 'react-router-dom';
import Cart from './Cart';
import locale from '../Locale';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { actionCreators } from '../store/Configurator';
import Brands from './Brands';
import logoImage from '../images/logo.png';
import logoVarialis from '../images/logo_Varialis.png';

import Helper from '../Helper';
import Languages from './Languages';
import Loading from 'react-fullscreen-loading';

interface IProps {
	user?: any;
	theme?: any;
	navCartOpen?: boolean;
	inquiryDialogOpen?: boolean;
	part?: any;
	configuration?: any;
	numberOfBrands?: number;
}

interface IState {
	isOpen: boolean;
	userOpen: boolean;
	cartOpen: boolean;
	inquiryDialogOpen: boolean;
	confirmationModal: boolean;
	confirmationModalTitle: string;
	confirmationModalMessage: string;
	confirmationModalAction: string;
	language: string;
	brand: any;
	loader: boolean;
}

class NavMenu extends React.Component<IProps, IState> {
	constructor(props: IProps) {
		super(props);
		this.state = {
			isOpen: false,
			userOpen: false,
			cartOpen: false,
			inquiryDialogOpen: false,
			confirmationModal: false,
			confirmationModalTitle: '',
			confirmationModalMessage: '',
			confirmationModalAction: '',
			language: '',
			brand: null,
			loader: this.props.configuration.loader,
		};
	}
	componentDidUpdate(nextProps: any) {
		if (nextProps.navCartOpen !== this.props.navCartOpen && this.props.navCartOpen === true) {
			const state: IState = this.state;
			state.cartOpen = true;
			this.setState(state);
			(this.props as any).setCartOpen(false);
		}

		if (nextProps.inquiryDialogOpen !== this.props.inquiryDialogOpen && this.props.inquiryDialogOpen === true) {
			const state: IState = this.state;
			state.cartOpen = true;
			this.setState(state);
			(this.props as any).setCartOpen(false);
		}
	}
	componentWillReceiveProps(prevProps: any) {
		if (prevProps.configuration.loader !== this.props.configuration.loader) {
			this.setState({ loader: this.props.configuration.loader });
		}
	}
	redirect() {
		this.toggleConfirmationModal();
		(this.props as any).clearPart(true);
		setTimeout(() => {
			if (Helper.userInstaller()) {
				window.location.href = process.env.REACT_APP_ROOT_PATH + '/admin/allProjects';
			} else if (Helper.userEditor()) {
				window.location.href = process.env.REACT_APP_ROOT_PATH + '/admin/myProjects';
			} else if(window.location.pathname.toLowerCase().replace(/\/$/, '').indexOf('documents') >= 0) {
				// eslint-disable-next-line no-self-assign
				window.location.href = window.location.href;
			}
			else{
				window.location.href = process.env.REACT_APP_ROOT_PATH + '/configurator/selection';
			}
		}, 700);
	}
	confirmSetLanguage(lang: string) {
		(this.props as any).configuration.loader = true;
		locale.setLanguage(lang);
		(this.props as any).setLanguage(lang);
		this.redirect();
	}
	confirmSetBrand(brand: any) {
		localStorage.setItem('theme', JSON.stringify(brand));
		localStorage.setItem('brandPartnerId', brand.brandPartnerId);
		(this.props as any).setBrand(
			brand.logo,
			brand.logotype,
			brand.title,
			brand.header,
			brand.color,
			brand.headerFontColor,
			brand.brandPartnerId,
			brand.buttonColor,
			brand.buttonFontColor
		);
		this.redirect();
	}
	confirmationAction(action: string) {
		if (action === 'setLanguage') {
			this.confirmSetLanguage(this.state.language);
		} else if (action === 'setBrand') {
			this.confirmSetBrand(this.state.brand);
		}
	}
	toggleConfirmationModal() {
		const state: any = this.state;
		state.confirmationModal = !this.state.confirmationModal;
		const lang = localStorage.getItem('selectedLang') ?? '';
		const brand = localStorage.getItem('selectedBrand') ?? '';
		if (lang !== '') {
			state.confirmationModalTitle = locale.languageChange;
			state.confirmationModalMessage = locale.languageChangeConfirm;
			state.confirmationModalAction = 'setLanguage';
			state.language = lang;
			localStorage.removeItem('selectedLang');
		} else if (brand !== '') {
			state.confirmationModalTitle = locale.brandChange;
			state.confirmationModalMessage = locale.brandChangeConfirm;
			state.confirmationModalAction = 'setBrand';
			state.brand = JSON.parse(brand);
			localStorage.removeItem('selectedBrand');
		}
		this.setState(state);
	}
	toggle() {
		const state: IState = this.state;
		state.isOpen = !state.isOpen;
		this.setState(state);
	}
	userToggle() {
		const state: IState = this.state;
		state.userOpen = !state.userOpen;
		this.setState(state);
	}
	cartToggle(open: boolean) {
		const state: IState = this.state;
		state.cartOpen = open;
		this.setState(state);
	}
	render() {
		const pathName = window.location.pathname.toLowerCase().replace(/\/$/, '');
		
		const loggedIn = Helper.userLoggedIn();
		const headerTitle = this.props.theme && this.props.theme.header ? this.props.theme.header : '';
		const isAdmin = Helper.userAdmin();
		const isInstaller = Helper.userInstaller();
		const isPublicDocuments = pathName.indexOf('documents') >= 0;

		const logo = this.props.theme && this.props.theme.logo ? this.props.theme.logo : logoImage;
		const logotype = this.props.theme && this.props.theme.logotype ? this.props.theme.logotype : null;
		const themeHeaderColor = isPublicDocuments ? '#fff' : (this.props.theme && this.props.theme.color ? this.props.theme.color : '#86A33C');

		const showBrandsSelection = this.props.numberOfBrands && this.props.numberOfBrands > 1;
		const { confirmationModal, confirmationModalMessage, confirmationModalTitle, confirmationModalAction } = this.state;
		
		const buttonColor = isPublicDocuments ? '#86a33c': Helper.themeButtonColor();
		const buttonFontColor = isPublicDocuments ? '#000000': Helper.themeButtonFontColor();

		const fontColor = isPublicDocuments ? '#565658': (this.props.theme && this.props.theme.headerFontColor ? this.props.theme.headerFontColor : '#fff');

		return this.state.loader ? (
			<Loading loading loaderColor={buttonColor} />
		) : (
			<header>
				<Navbar style={{ backgroundColor: themeHeaderColor }} className='navbar-expand-sm navbar-toggleable-sm header-menu'>
					<Container fluid>
						<NavLink
							tag={RRNavLink}
							className='navbar-brand'
							to={process.env.REACT_APP_ROOT_PATH + (isAdmin ? '/admin/myprojects' : isInstaller ? '/admin/allprojects' : '/configurator/selection')}
							onClick={() => {
								window.location.href =
									process.env.REACT_APP_ROOT_PATH + (isAdmin ? '/admin/myprojects' : isInstaller ? '/admin/allprojects' : '/configurator/selection');
							}}
						>
							<img className='logo' alt='Logo' src={ isPublicDocuments ? logoVarialis : logo} />
							<span className='logo-title' style={{ color: fontColor }}>
								{isPublicDocuments ? locale.configurator : headerTitle}
							</span>
						</NavLink>
						<NavbarToggler onClick={this.toggle} style={{ display: 'none' }} className='mr-2' />
						{logotype ? (
							<div className='outer-wrapper'>
								<div className='logo-frame'>
									<img className='logotip' alt='Logotype' src={logotype} />
								</div>
							</div>
						) : null}
						<ul className='navbar-nav flex-grow social'>
							{(isAdmin || isInstaller) && showBrandsSelection && loggedIn ? (
								<NavItem>
									<div>
										<UncontrolledDropdown>
											<DropdownToggle
												style={{
													backgroundColor: 'transparent',
													borderColor: fontColor,
													color: fontColor,
													borderWidth: '1px',
													borderStyle: 'solid',
												}}
												caret
											>
												<span
													className='fa fa-copyright'
													style={{ marginLeft: '0px', fontSize: '33px', color: fontColor }}
													aria-hidden='true'
												></span>
												<span className='clearfix d-none d-lg-inline-block' style={{ color: fontColor }}>
													{this.props.theme && this.props.theme.title ? this.props.theme.title : locale.brands}
												</span>
											</DropdownToggle>
											<DropdownMenu style={{ width: 190 }}>
												<Brands toggleConfirmationModal={() => this.toggleConfirmationModal()} />
											</DropdownMenu>
										</UncontrolledDropdown>
									</div>
								</NavItem>
							) : null}
							<NavItem>
								<div>
									<UncontrolledDropdown>
										<DropdownToggle
											style={{
												backgroundColor: 'transparent',
												borderColor: fontColor,
												color: fontColor,
												borderWidth: '1px',
												borderStyle: 'solid',
											}}
											caret
										>
											<span
												className='fa fa-globe'
												style={{ marginLeft: '0px', fontSize: '33px', color: fontColor }}
												aria-hidden='true'
											></span>
											<span className='clearfix d-none d-lg-inline-block text-uppercase' style={{ color: fontColor }}>
												{Helper.userLang().replace('sl', 'si')}
											</span>
										</DropdownToggle>
										<DropdownMenu style={{ width: 80 }}>
											<Languages toggleConfirmationModal={() => this.toggleConfirmationModal()} />
										</DropdownMenu>
									</UncontrolledDropdown>
								</div>
							</NavItem>
							{!isAdmin && !isInstaller && !isPublicDocuments ? (
								<NavItem>
									<div>
										<Dropdown isOpen={this.state.cartOpen} toggle={() => this.cartToggle(true)}>
											<DropdownToggle
												style={{
													backgroundColor: 'transparent',
													borderColor: fontColor,
													color: fontColor,
													borderWidth: '1px',
													borderStyle: 'solid',
												}}
												caret
											>
												<span
													className='fa fa-cart-plus'
													style={{ marginLeft: '0px', fontSize: '33px', color:fontColor }}
													aria-hidden='true'
												></span>
												<span className='clearfix d-none d-lg-inline-block' style={{ color: fontColor }}>
													{locale.cart}
												</span>
											</DropdownToggle>
											<DropdownMenu right>
												<Button
													onClick={() => this.cartToggle(false)}
													color='cart-close'
													className='close float-right d-none d-md-block'
													aria-label='Close'
												>
													<span aria-hidden='true'>×</span>
												</Button>
												<Cart closeCart={() => this.cartToggle(false)} cartOpen={this.state.cartOpen} />
											</DropdownMenu>
										</Dropdown>
									</div>
								</NavItem>
							) : null}
							{!isPublicDocuments ? (
								<NavItem>
									<Dropdown isOpen={this.state.userOpen} toggle={() => this.userToggle()}>
										<DropdownToggle
											style={{
												backgroundColor: 'transparent',
												borderColor: fontColor,
												color: fontColor,
												borderWidth: '1px',
												borderStyle: 'solid',
											}}
											caret
										>
											<span
												className='fa fa-user'
												style={{ marginLeft: '0px', fontSize: '33px', color: fontColor }}
												aria-hidden='true'
											></span>
											<span className='clearfix d-none d-lg-inline-block' style={{ color: fontColor }}>
												{loggedIn && this.props.user && this.props.user.name ? this.props.user.name : locale.unknownUser}
											</span>
										</DropdownToggle>
										<DropdownMenu right>
											<DropdownItem
												style={loggedIn ? { display: 'none' } : {}}
												tag={RRNavLink}
												disabled={loggedIn}
												to={process.env.REACT_APP_ROOT_PATH + '/Login'}
											>
												{locale.signIn}
											</DropdownItem>
											<DropdownItem
												style={!loggedIn ? { display: 'none' } : {}}
												tag={RRNavLink}
												disabled={!loggedIn}
												to={process.env.REACT_APP_ROOT_PATH + '/Profile'}
											>
												{locale.profile}
											</DropdownItem>
											<DropdownItem
												style={!loggedIn ? { display: 'none' } : {}}
												tag={RRNavLink}
												disabled={!loggedIn}
												to={process.env.REACT_APP_ROOT_PATH + '/Logout'}
											>
												{locale.signOut}
											</DropdownItem>
										</DropdownMenu>
									</Dropdown>
								</NavItem>
							) : null}
						</ul>
					</Container>
				</Navbar>
				<Modal size='lg' isOpen={confirmationModal} toggle={() => this.toggleConfirmationModal()}>
					<ModalHeader toggle={() => this.toggleConfirmationModal()}>{confirmationModalTitle}</ModalHeader>
					<ModalBody>
						{confirmationModalMessage}
						<br />
						<br />
						<Button
							className={'button btn-secondary my-2'}
							onClick={() => this.confirmationAction(confirmationModalAction)}
							style={{ backgroundColor: buttonColor, color: buttonFontColor }}
						>
							{locale.confirm}
						</Button>
						<Button
							className={'button btn-secondary my-2 ml-3'}
							onClick={() => this.toggleConfirmationModal()}
							style={{ backgroundColor: buttonColor, color: buttonFontColor }}
						>
							{locale.cancel}
						</Button>
					</ModalBody>
				</Modal>
			</header>
		);
	}
}
export default connect(
	(state: any) => state.configurator,
	(dispatch) => bindActionCreators(actionCreators, dispatch)
)(NavMenu);
